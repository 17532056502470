import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';

import rootReducer from '../reducers';
import { appConfigDefaultState } from '../reducers/appConfig';
import { authDefaultState } from '../reducers/auth';
import { companyDefaultState } from '../reducers/company';

export const initialStore = {
  appConfig: appConfigDefaultState(),
  auth: authDefaultState(),
  company: companyDefaultState()
};

const middleware = [];

if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}

export default createStore(
  rootReducer,
  initialStore,
  applyMiddleware(...middleware)
);
